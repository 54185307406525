import React from 'react';
import { CountryProvider } from './CountryContext';
import { RoutesHandingProvider } from './RoutesHandingContex';
import { ScrollHandingProvider } from './ScrollHandingContex';
// import { UserProvider } from './UserContext';

export const AppProvider = ({ children }) => (
  <CountryProvider>
    <RoutesHandingProvider>
      <ScrollHandingProvider>
    {children}
    </ScrollHandingProvider>
    </RoutesHandingProvider>
    
  </CountryProvider>
);
