import React, { useEffect, useState } from "react";
import "./lowBalance.css";
import { useNavigate } from "react-router-dom";
import { ProfileService } from "../../services/ProfileService";

function LowBalancePopup({ onClose }) {
  const navigation = useNavigate();
  const profileService = ProfileService();
  const [myBalanceData, setMyBalanceData] = useState([]);
  const [balanceData, setBalanceData] = useState(0);

  useEffect(() => {
    GetPlanList();
    GetMyBalance();
  }, []);

  const GetPlanList = async () => {
    try {
      const response = await profileService.GetPlanlist();
      if (response.success == true) {
        console.log(response);
        setMyBalanceData(response.result.inr_data);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      if (error.message === "Session expired. Please log in again.") {
        localStorage.clear();
        navigation("/login", { replace: true });
      } else {
        throw new Error(error.message);
      }
    }
  };
  const GetMyBalance = async () => {
    try {
      const response = await profileService.GetMyBalance();
      if (response.success == true) {
        setBalanceData(response.maleBalance.purchase_points);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      if (error.message === "Session expired. Please log in again.") {
        localStorage.clear();
        navigation("/login", { replace: true });
      } else {
        throw new Error(error.message);
      }
    }
  };

  const handleNavigation = (points, amount, id) => {
    navigation("/recharge", {
      state: { points: points, amount: amount, plan_id: id },
    });
  };

  return (
    <>
      <div className="lowbalance-popup-overlay" onClick={onClose}>
        <div className="lowbalance-popup-box">
          <div className="lowbalance-popup-outer">
            <div className="lowbalance-header">
              <div className="lowbalance-header-text">My Coin</div>
              <div className="lowbalance-daimond-outer">
                <div className="daimond-img-outer">
                  <img
                    src={require("../../assets/daimondnew.png")}
                    alt="daimond"
                  />
                </div>
                <p className="lowbalance-dimondTxt">{balanceData}</p>
              </div>
            </div>
            <div className="lowbalance-coin-bx-outer">
              {myBalanceData.map((items) => (
                <div
                  className="lowbalance-coin-bx"
                  key={items.id}
                  onClick={() =>
                    handleNavigation(items.points, items.amount, items.id)
                  }
                >
                  <div className="lowbalance-daimond-outer">
                    <div className="daimond-img-outer">
                      <img
                        src={require("../../assets/daimondnew.png")}
                        alt="daimond"
                      />
                    </div>
                    <p className="lowbalance-dimondTxt">{items.points}</p>
                  </div>
                  <div>
                    <p className="rupeeText">&#8377;{items.amount}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center lowbalnce-footer">
              <p className="text-check">Paid but coin not received?</p>
              <p className="text-check">
                Email us:{" "}
                <span className="text-pink">zeepliveofficial@gmail.com</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LowBalancePopup;
