import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { EffectFade, Mousewheel, Pagination } from "swiper/modules";
import "./privateList.css";
import { ProfileService } from "../../../services/ProfileService";
import { HomeService } from "../../../services/HomeService";
import PermissionStatus from "../../../constant/PermissionStatus";
import LowBalancePopup from "../../../components/lowBalance-popup/lowBalance";
import PermissionPopup from "../../../components/permission-popup/PermissionPopup";
import { useNavigate } from "react-router-dom";
import MediaDetectedPopup from "../../../components/mediadetectedPopup/mediaDetectedPopup";
import { VideoCallService } from "../../../services/VideoCallService";
import "../../../components/privateList/privateListComponent.css";
import Lottie from "lottie-react";
import locationSvg from "../../../assets/location.svg";
import femaleSvg from "../../../assets/female.svg";
import levelIcon from "../../../assets/charmingmoon.png";
import placeholderImage from "../../../assets/placeHolderForListing.webp";
import callButtonAnimation from "../../../assets/animated-call-button.json";
import broadCastAnimation from "../../../assets/broad_cast_loader.json";
import { useScrollHandling } from "../../../contexts/ScrollHandingContex";

function PrivateList() {
  const navigation = useNavigate();
  const profileService = ProfileService();
  const homeService = HomeService();
  const videoService = VideoCallService();
  const listRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [balanceData, setBalanceData] = useState(0);
  const [lowBalance, setLowbalance] = useState(false);
  const [allowPermissionPopup, setAllowPermissionPopup] = useState(false);
  const [permissionTypePopup, setPermissionTypePopup] = useState(null);
  const [hostDetail, setHostDetail] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const [mediaDetectedPopup, setMediaDetectedPopup] = useState(false);
  const [cameraMediaType, setCameraMediaType] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [permissionType, setPermissionType] = useState(PermissionStatus);
  const hostUserStreamRef = useRef(null);
  const [hostStreamRunning, setHostStreamRunning] = useState(false);
  const [stremLoading, setStremLoading] = useState(true);
  const [shadowClass, setShadowClass] = useState("");

  const {
    saveDataforPrivateList,
    resetDataforPrivateList,
    timeStampForPrivateList,
    privateList,
    lastPageForPrivateList,
    scrollPostionForPrivateList,
    privateListIndex,
    saveDataForPrivateListIndex,
    saveDataForScrollForPrivate,
  } = useScrollHandling();

  const [privateCallList, setPrivateCallList] = useState(privateList || []);
  const [currentIndex, setCurrentIndex] = useState(privateListIndex || 0);
  const [lastPage, setLastPage] = useState(lastPageForPrivateList || 0);
  const [isScrolled, setIsScrolled] = useState(false);

  const swiperRef = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const initializeData = async () => {
      if (privateList && privateList.length > 0) {
        if (privateListIndex !== null && swiperRef.current?.swiper) {
          swiperRef.current.swiper.slideTo(privateListIndex);
        }
      } else {
        genrateZegoToken();
        resetDataforPrivateList();
        fetchData(currentIndex);
      }
      setIsInitialized(true); 
    };

    initializeData();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      GetMyBalance();
    }
    videoService.handleStreamListeners(handleStreamUpdate);
    
    return () => { 
      cleanup();
    };
  }, []);

  const cleanup = async () => {
    try {
      await videoService.stopStreaming(localStorage.getItem("hoststramId"));
      await videoService.logoutRoom(localStorage.getItem("lastRoomID"));
    } catch (error) {
      console.error("Error during cleanup:", error);
    }
  };


  useEffect(() => {
    if (isInitialLoad && privateCallList.length > 0) {
      PlayStream(currentIndex);
      setIsInitialLoad(false); 
    }
  }, [privateCallList, isInitialLoad]);

  const fetchData = async (page) => {
    if (loading || page > lastPage) return;
    setLoading(true);
    try {
      const response = await homeService.LiveBroadcastList(page);
      if (response.success) {
        const updatedList = isRefreshing
          ? response.result.data
          : [...privateCallList, ...response.result.data];

        setPrivateCallList(updatedList);
        setLastPage(response.result.last_page);

        saveDataforPrivateList(
          updatedList,
          Date.now().toString(),
          response.result.last_page
        );

      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  const fetchNextPage = () => {
    setCurrentPage((prev) => {
      const nextPage = prev + 1;
      fetchData(nextPage);
      return nextPage;
    });
  };

  const GetMyBalance = async () => {
    try {
      const response = await profileService.GetMyBalance();
      if (response?.success == true) {
        setBalanceData(response.maleBalance.purchase_points);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      if (error.message === "Session expired. Please log in again.") {
        localStorage.clear();
        navigation("/login");
      } else {
        throw new Error(error.message);
      }
    }
  };

  const getBrowserPermissions = async () => {
    const cameraPermission = await navigator.permissions.query({
      name: "camera",
    });
    const microphonePermission = await navigator.permissions.query({
      name: "microphone",
    });

    return {
      camera: cameraPermission.state,
      microphone: microphonePermission.state,
    };
  };

  const startVideoCall = (id, profileId, name, rate, image) => {
    sessionStorage.setItem("fromHome", "true");
    const dataToPass = { id, profileId, name, image, rate };
    navigation("/video", { state: dataToPass });
  };

  const checkAllScenariosForCalls = async (
    id,
    profileId,
    name,
    rate,
    image
  ) => {
    const permission = await getBrowserPermissions();
    if (
      permission.camera === permissionType.GRANTED &&
      permission.microphone === permissionType.GRANTED
    ) {
      setIsOpen(false);
      setAllowPermissionPopup(false);
      startVideoCall(id, profileId, name, rate, image);
    } else if (
      permission.camera === permissionType.PROMPT ||
      permission.microphone === permissionType.PROMPT
    ) {
      setPermissionTypePopup(permissionType.PROMPT);
      setConfirmationMessage(
        "Access to your camera and microphone is required to make a call. Please allow permissions to continue."
      );
      setIsOpen(true);
      setAllowPermissionPopup(true);
    } else if (
      permission.camera === permissionType.DENIED ||
      permission.microphone === permissionType.DENIED
    ) {
      setPermissionTypePopup(permissionType.DENIED);
      setConfirmationMessage(
        "Access to your camera and microphone is required to make a call. Please enable permissions in your browser settings."
      );
      setIsOpen(true);
      setAllowPermissionPopup(true);
    }
  };

  const checkVideoDevice = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      return videoDevices.length > 0;
    } catch (error) {
      console.error("Error checking devices:", error);
      return false;
    }
  };

  const checkAudioDevice = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      return audioInputDevices.length > 0;
    } catch (error) {
      console.error("Error checking devices:", error);
      return false;
    }
  };

  const handleNavigate = async (id, profileId, name, rate, image) => {
    const videoDeviceAvailable = await checkVideoDevice();
    if (videoDeviceAvailable) {
      const audioDeviceAvailable = await checkAudioDevice();
      if (audioDeviceAvailable) {
        setHostDetail({ id, profileId, name, rate, image });
        const token = localStorage.getItem("token");
        if (!token) {
          navigation("/login", { replace: true });
        } else {
          if (balanceData < rate * 60) {
            setLowbalance(true);
            document.body.style.overflow = "hidden";
            return;
          }
          checkAllScenariosForCalls(id, profileId, name, rate, image);
        }
      } else {
        setCameraMediaType(false);
        setTitle("No Microphone Detected");
        setMessage(
          "Your device does not have a microphone or the microphone is disabled"
        );
        setMediaDetectedPopup(true);
      }
    } else {
      setCameraMediaType(true);
      setTitle("No Camera Detected");
      setMessage(
        "It seems your device does not have a camera or the camera is disabled."
      );
      setMediaDetectedPopup(true);
    }
  };

  const handleRefresh = async (page) => {
    if (isRefreshing) return;
    setIsRefreshing(true);
    try {
      const response = await homeService.privateCallList(1);
      if (response.success) {
        setPrivateCallList(response.result.data);
        setCurrentPage(1);
        setLastPage(response.result.last_page);
      }
    } catch (error) {
      console.error("Error during refresh", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleClose = () => {
    setLowbalance(false);
    document.body.style.overflow = "auto";
  };
  const handlenavigateForhostProfile = (
    id,
    profileId,
    rate,
    broad_channel_name
  ) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigation("/login", { replace: true });
    } else {
      const dataToPass = { id, profileId, balanceData, broad_channel_name };
      navigation("/profileDetails", { state: dataToPass });
    }
  };

  const allowPermission = async (
    type,
    id = null,
    profileId = null,
    name = "",
    rate = 0,
    image = ""
  ) => {
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      checkAllScenariosForCalls(id, profileId, name, rate, image);
    } catch (error) {
      setIsOpen(false);
      setAllowPermissionPopup(false);
    }
  };
  const closeMediaDetectedPopup = async () => {
    setMediaDetectedPopup(false);
  };

  const genrateZegoToken = async () => {
    try {
      const response = await homeService.GenerateZegoTokenInWeb();
      if (response.success) {
        localStorage.setItem("tokenforroomlogin", response.result.token);
        localStorage.setItem("dummyUserId", response.result.dummy_user_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const PlayStream = async (num = 0) => {
       
      
   //setStremLoading(true); 
    const isLogin = await videoService.loginInRoom(localStorage.getItem("dummyUserId"),privateCallList[num].broad_channel_name,localStorage.getItem("tokenforroomlogin"));
    if (isLogin) {
      localStorage.setItem(
        "lastRoomID",
        privateCallList[num].broad_channel_name
      );
      const hoststramId = `${privateCallList[num].broad_channel_name}_stream`;
      localStorage.setItem("hoststramId", hoststramId);
      //hostUserStreamRef.current.srcObject = null;
    }
  };

  const handleStreamUpdate = async (
    roomID,
    updateType,
    streamList,
    extendedData
  ) => {
       
    setStremLoading(true);
    const isHostStreamAffected = streamList.some(
      (stream) => stream.streamID === localStorage.getItem("hoststramId")
    );
    if (updateType === "ADD" && isHostStreamAffected) {
      await playHostStream();
      
      setHostStreamRunning(true);
      showLoader();
    }
    if (updateType === "DELETE" && isHostStreamAffected) {
      await videoService.stopPlayingStream(localStorage.getItem("hoststramId"));
      setHostStreamRunning(false);
    }
  };

  const showLoader = () => {
    setTimeout(() => {
      setStremLoading(false);
    }, 2000);
  };

  const playHostStream = async () => {
    await videoService.startPlayingStream(
      localStorage.getItem("hoststramId"),
      hostUserStreamRef.current
    );
  };

  // const LogOutFromRoom = async (roomId) => {
  //   await videoService.stopPlayingStream(localStorage.getItem("hoststramId"));
  //   await videoService.logoutRoom(roomId);
    
  // };

  const SwitchRoom = async (fromRoomId,toRoomId)=>{
    const config = {
      token: localStorage.getItem("tokenforroomlogin"), 
      maxMemberCount: 100,         
      isUserStatusNotify: true    
  };
    await videoService.switchRoom(fromRoomId,toRoomId,config)
  }

  const calculateAge = (dob) => {
    const [day, month, year] = dob.split("-").map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleBeforeSlideChange = async(direction, activeIndex) => {
    if (!isInitialized) return;
    setHostStreamRunning(false);
    const hoststramId = `${privateCallList[activeIndex].broad_channel_name}_stream`;
    localStorage.setItem("hoststramId", hoststramId);
    //await LogOutFromRoom(privateCallList[currentIndex]?.broad_channel_name);
    setCurrentIndex(activeIndex);
    saveDataForPrivateListIndex(activeIndex);
 
    localStorage.setItem("lastRoomID",privateCallList[activeIndex].broad_channel_name)
    await SwitchRoom(privateCallList[currentIndex]?.broad_channel_name.toString(),privateCallList[activeIndex]?.broad_channel_name.toString());
  
    
    // ---------use this if you want to add particular logic of up and down -----------
    // if (direction === 'next') {
    // } else if (direction === 'prev') {
    // }
    if (
      activeIndex === privateCallList.length - 2 &&
      currentPage < lastPage &&
      !loading
    ) {
      fetchNextPage();
    }
  };


  return (
    <>
      <div className="private-host-list-container">
        <div className={`swiper-shadow`}/>
        <Swiper
          direction="vertical"
          ref={swiperRef}
          slidesPerView={1}
          mousewheel={true}
          style={{ height: "100%", borderRadius: 15, position: "relative", zIndex:2 ,overflow: "hidden", }}
          modules={[Mousewheel, Pagination , EffectFade]}
          onTransitionStart={(swiper) => {
            const currentIndex = swiper.activeIndex;
            setCurrentIndex(currentIndex); 
          }}
          onSlideNextTransitionStart={(swiper) =>
            handleBeforeSlideChange("next", swiper.activeIndex)
          }
          onSlidePrevTransitionStart={(swiper) =>
            handleBeforeSlideChange("prev", swiper.activeIndex)
          }
        >
          {privateCallList.map((items, index) => (
            <SwiperSlide key={index}>
              <div className="private-list-outer" style={{
            visibility: currentIndex === index ? "visible" : "hidden",
            transform: currentIndex === index ? "translateY(0)" : "translateY(100%)",
          }}>
                <div className="swiper-card-outer">
                  <div
                    className="background-scroll"
                    key={`${items.id}-${index}`}
                    onClick={() =>
                      handlenavigateForhostProfile(
                        items.id,
                        items.profile_id,
                        items.new_call_rate,
                        items.broad_channel_name
                      )
                    }
                    style={{
                      backgroundImage: `url(${items.profile_image}), url(${placeholderImage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="private-list-inner-top">
                      <div className="private-list-live-icon">
                        <div className="private-list-red-dot"></div>
                        <h1 className="private-list-live-text">Live</h1>
                      </div>
                      {currentIndex === index && (
                        <div
                          style={{
                            display: hostStreamRunning ? "block" : "none",
                          }}
                        >
                          <div
                            style={{
                              visibility: stremLoading ? "visible" : "hidden",
                              backgroundColor: "#0F0D49",
                            }}
                            className="live-streaming-ui"
                          >
                            <Lottie
                              animationData={broadCastAnimation}
                              loop={true}
                              className="animation-streming"
                            />
                          </div>
                          <video
                            style={{
                              visibility: stremLoading ? "hidden" : "visible",
                            }}
                            className="live-streaming-ui"
                            ref={hostUserStreamRef}
                            muted
                            autoPlay
                            playsInline
                          />
                        </div>
                      )}
                    </div>
                    <div className="private-list-inner-bottom">
                      <div className="private-list-bottom-outer">
                        <div className="private-list-user-box-name">
                          <p className="private-list-user-name">{items.name}</p>
                          <div className="private-list-button-outer">
                            <div className="private-list-danger-btn mr10">
                              <img src={levelIcon} />
                              <p className="private-list-profile-num-text">
                                Lv19
                              </p>
                            </div>
                            <div className="private-list-location-btn mr10">
                              <div className="private-list-location-icon-img">
                                <img
                                  className="private-list-location-img"
                                  src={locationSvg}
                                  alt="location"
                                />
                              </div>
                              <span className="private-list-location-text">
                                {items.city}
                              </span>
                            </div>
                            <div className="private-list-danger-btn mr10">
                              <img src={femaleSvg} />
                              <p className="private-list-profile-num-text">
                                {calculateAge(items.dob)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="private-list-call-img-outer"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNavigate(
                              items.id,
                              items.profile_id,
                              items.name,
                              items.new_call_rate,
                              items.profile_image
                            );
                          }}
                        >
                          <Lottie
                            animationData={callButtonAnimation}
                            loop={true}
                            className="private-list-call-video"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {lowBalance && <LowBalancePopup onClose={handleClose} />}
      {loading && (
        <div className="bottom-loader">
          <div className="loader-dots">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      {allowPermissionPopup && (
        <PermissionPopup
          isOpen={isOpen}
          type={permissionTypePopup}
          hostInfo={hostDetail}
          message={confirmationMessage}
          onAllow={allowPermission}
        />
      )}
      {mediaDetectedPopup && (
        <MediaDetectedPopup
          cameraMediaType={cameraMediaType}
          title={title}
          message={message}
          handleOnclick={closeMediaDetectedPopup}
        />
      )}
    </>
  );
}

export default PrivateList;
