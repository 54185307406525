import React from 'react'
import PrivateRoute from '../routes/PrivateRoute'
import BottomTabs from '../components/btmTabs/bottomTabs'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer , toast  } from 'react-toastify';

function MainScreen() {
  return (
    <> 
      <ToastContainer/>
      <PrivateRoute/>
      {/* <BottomTabs/> */}
    </>
  )
}
export default MainScreen