import React, { useEffect, useState } from "react";
import PrivateRoute from "./routes/PrivateRoute";
import "./style.css";
import "./App.css";
import { AppProvider } from "./contexts/AppProvider";
import ReactGA from "react-ga4";
import MainScreen from "./entrypoint/MainScreen";
import useViewportHeight from "./components/viewPortHeight/viewportheight";
import { useLocation } from "react-router-dom";
import RotateScreen from "./components/rotateScreen/RotateScreen";

ReactGA.initialize(process.env.REACT_APP_Measurement_ID);

function App() {
  useViewportHeight();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    console.log("Page view sent:", location.pathname);
  }, [location]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.log("Service Worker registration failed:", error);
        });
    }
  }, []);

  const [orientation, setOrientation] = useState(
    window.innerWidth > window.innerHeight ? "landscape" : "portrait"
  );
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Enhanced Mobile Detection: User Agent + Screen Dimensions
    const detectMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isMobileDevice =
        /android|iphone|ipad|ipod|blackberry|windows phone|opera mini|iemobile|mobile/i.test(
          userAgent
        );
      const isSmallScreen = window.innerWidth <= 768; // Typical mobile breakpoint
      setIsMobile(isMobileDevice || isSmallScreen);
    };

    detectMobile();

    const handleResize = () => {
      const newOrientation =
        window.innerWidth > window.innerHeight ? "landscape" : "portrait";
      setOrientation(newOrientation);
      detectMobile();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
      {isMobile && orientation === "landscape" && (
       <RotateScreen/>
      )}
      <AppProvider>
        <MainScreen />
      </AppProvider>
    </>
  );
}

export default App;
