import { ZegoExpressEngine } from 'zego-express-engine-webrtc';
import { Get, GetMathod, Post, PostMathod, PostMathodForUpdateFCMToken } from '../utils/ApiUtility.js';

const appID = Number(process.env.REACT_APP_ZEGO_APP_ID);
const server = process.env.REACT_APP_ZEGO_SERVER;
const zg = new ZegoExpressEngine(appID, server);
let localStream = null;
export const VideoCallService = () => {

  const token = localStorage.getItem('token');


  const DialCallZegoSendNotification = async (params) => {
    try {
      const query = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
      const response = await GetMathod(`dialCallZegoSendNotificationLatest?${query}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getcategoryGiftsauth = async () => {
   const token = localStorage.getItem('token');
    const header = {
      authorization: `Bearer ${token}`,
    }
      return await Get('getcategoryGiftsauth',header);
  };

  const loginInRoom = async (userId,roomID, token) => {
    try {
      return await zg.loginRoom(roomID, token, { userID: userId }, { userUpdate: true, isUserStatusNotify: true });
    } catch (error) {
      console.error('Error joining room', error);
    }
  };

  const coinPerSecondUserBusyNewLatest = async (model) => {
    try {
      const headers = {
        appid: "21",
        authorization: `Bearer ${token}`,
      };
      const response = await Post('coin-per-second-user-busy-new-latest', model, headers);
      return response;
    } catch (error) {
      console.error('Error getting coin per second user busy new latest', error);
    }
  }

  const addEventListeners = (onHostConnected, onHostDisconnected) => {
    zg.on('roomUserUpdate', async (roomID, updateType, userList) => {
      if (updateType === 'ADD') {

        const endTimeDuration = localStorage.getItem('EndTimeDuration');
        const endTimeDurationNumber = endTimeDuration ? Number(endTimeDuration) : 0;
        const messageObject = {
          action_type: 'auto_end',
          sender_id: (endTimeDurationNumber * 1000).toString(),
          rec_name: localStorage.getItem('user_name'),
        };
        const messageSend = JSON.stringify(messageObject);
        const filteredUserList = [userList[0].userID];
        try {
          const res = await zg.sendCustomCommand(localStorage.getItem("roomId"), messageSend, filteredUserList);
        } catch (error) {
          console.error('Failed to send message:', JSON.stringify(error));
        }
        onHostConnected();
      } else if (updateType === 'DELETE') {
        onHostDisconnected();
      }
    });
  
  };

  const handleStreamListeners = (handleStreamUpdate) => {
    zg.on('roomStreamUpdate',handleStreamUpdate);
  };

  const startPublishingStream = async (userStreamId, localUserStream) => {
    try {
      localStream = await zg.createStream({
        camera: {
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
          },
          video: true,
        },
      });
      zg.startPublishingStream(userStreamId, localStream);
      localUserStream.srcObject = localStream;
      localUserStream.muted = true;
    } catch (error) {
      console.error('Error publishing local stream:', error);
    }
  };

  const startPlayingStream = async (hostStreamId, hostUserStream) => {
    try {
      const remoteStream = await zg.startPlayingStream(hostStreamId);
      hostUserStream.srcObject = remoteStream;
    } catch (error) {
      console.error('Error playing remote stream:', error);
    }
  };


  const stopPublishingAndPlayingStreams = async (roomId, userStreamId, hostStreamId) => {
    try {
      zg.stopPublishingStream(userStreamId)
      zg.destroyStream(localStream)
      zg.stopPlayingStream(hostStreamId)
      zg.logoutRoom(roomId)
      zg.off('roomUserUpdate');
    } catch (error) {
      console.error('Error stop publishing and playing streams:', error);
    }
  };

  const stopPlayingStream = async (hostStreamId) => {
    zg.stopPlayingStream(hostStreamId);
  };
  const stopStreaming = async (hostStreamId) => {
    zg.stopPlayingStream(hostStreamId);
    zg.off('roomStreamUpdate');
  };
  const logoutRoom = async (roomId) => {
    zg.logoutRoom(roomId);
  };

  const callCutByUserSideAndSendNotification = async (connectingUserId) => {
    return await PostMathod(`call-cut-by-userside-send-notification`, connectingUserId);
  };

  const updateFCMToken = async (fcmToken) => {
    return await PostMathodForUpdateFCMToken(`updateFCMToken`, fcmToken);
  };

  const sendCustomCommand = async (actionType, profileId) => {
    try {
      const commandContent = {
        action_type: actionType,
      };

      const commandString = JSON.stringify(commandContent);
      const targetUsers = [profileId.toString()];

      const result = await zg.sendCustomCommand(localStorage.getItem("roomId"), commandString, targetUsers);
      if (result) {
      } else {
        console.error('Failed to send custom command.');
      }
    } catch (error) {
      console.error('Error sending custom command:', error);
    }
  };

  const muteVideoStream = async (profileId) => {
    try {
      const success = zg.mutePublishStreamVideo(localStream, true, true);
      if (success) {
        await sendCustomCommand('pauseVideo', profileId);
      } else {
        console.error('Failed to mute video stream.');
      }
    } catch (error) {
      console.error('Error muting video stream:', error);
    }
  };

  const unMuteVideoStream = async (profileId) => {
    try {
      const success = zg.mutePublishStreamVideo(localStream, false, true);
      if (success) {
        await sendCustomCommand('playVideo', profileId);
      } else {
        console.error('Failed to unmute video stream.');
      }
    } catch (error) {
      console.error('Error unmuting video stream:', error);
    }
  };
  
  const switchRoom = async (fromRoomID, toRoomId, config={})=>{
    try {
      const result = await zg.switchRoom(fromRoomID,toRoomId,config);
      console.log('Room switch successful:', result);
      return result;
  } catch (error) {
      console.error('Error while switching rooms:', error);
      throw error; 
  }
  }




  return {
    DialCallZegoSendNotification,
    loginInRoom,
    addEventListeners,
    startPublishingStream,
    startPlayingStream,
    stopPublishingAndPlayingStreams,
    callCutByUserSideAndSendNotification,
    updateFCMToken,
    coinPerSecondUserBusyNewLatest,
    logoutRoom,
    unMuteVideoStream,
    muteVideoStream,
    stopPlayingStream,
    handleStreamListeners,
    stopStreaming,
    getcategoryGiftsauth,
    switchRoom
  };
};

