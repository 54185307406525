import React, { useEffect } from 'react';
import './PermissionPopup.css';
import PermissionStatus from '../../constant/PermissionStatus';

function PermissionPopup({ isOpen , type, hostInfo, message, onAllow, btnText = "Allow" }) {
    const onAllowPermission = () => {
        if (type === PermissionStatus.PROMPT) {
            onAllow(type, hostInfo.id, hostInfo.profileId, hostInfo.name, hostInfo.rate, hostInfo.image);
        }
        else if (type === PermissionStatus.DEFAULT) {
            onAllow(type);
        }
    };

    return (
        isOpen && type === PermissionStatus.PROMPT || type === PermissionStatus.DEFAULT ? (
            <div className="popup-overlay-permission">
                <div className="popup-box-permission">
                    <div className="popup-text-outer-permission">
                        <p className="popup-text-permission">{message}</p>
                    </div>
                    <div className="button-group-permission mt-2">
                        <div className="confirm-button-permission" onClick={() => onAllowPermission()}>
                            <p className="confirm-text-permission">
                                {btnText}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="popup-overlay-permission">
                <div className="popup-box-permission">
                    <div className="popup-text-outer-permission">
                        <p className="popup-text-permission">{message}</p>
                    </div>
                </div>
            </div>
        )
    );

}

export default PermissionPopup;

