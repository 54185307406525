import React from "react";
import "./RotateScreen.css";
import rotationSvg from '../../assets/rotation.svg'

const RotateScreen = () => {
  return (
    <div className="rotate-screen-container">
      <div className="rotate-screen-content">
        <div className="rotate-icon">
          <img
            src={rotationSvg}
            alt="Rotate Icon"
            className="rotate-image"
          />
        </div>
        <div className="rotate-text">
          <h1>Please turn device vertically</h1>
          <p>
          QeepLive best experience on the phone is in potrait mode only
          </p>
        </div>
      </div>
    </div>
  );
};

export default RotateScreen;
