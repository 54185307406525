import React from "react";
import './mediaDetectedPopup.css';
import CameraDetectedImage from '../../assets/camera-detected.svg';
import MicrophoneDetectedImage from '../../assets/microphone-detect.svg';
function MediaDetectedPopup({ cameraMediaType, title, message, handleOnclick }) {
  return (
    <div className="mediaDetected-popup-overlay" onClick={handleOnclick}>
      <div className="mediaDetected-popup-box">
        <div
          className="mediaDetected-image-outer"
          style={{
            backgroundImage: `url(${
              cameraMediaType
                ? CameraDetectedImage
                : MicrophoneDetectedImage
            })`,
          }}
        />
        <div className="mediaDetected-popup-text-outer mt-2">
          <h1 className="mediaDetected-popup-bold">{title}</h1>
          <p className="mediaDetected-popup-text">{message}</p>
        </div>
        <div className="mediaDetected-button-group mt-2">
          <div className="mediaDetected-confirm-button">
            <p className="mediaDetected-confirm-text" onClick={handleOnclick}>
              Ok
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MediaDetectedPopup;
