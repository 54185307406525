import React, { useEffect, useRef, useState } from 'react'
import './popularList.css'
import { ProfileService } from '../../../services/ProfileService';
import { HomeService } from '../../../services/HomeService';
import PermissionStatus from '../../../constant/PermissionStatus';
import BroadCastList from '../../../components/broadCastList/broadCastList';
import LowBalancePopup from '../../../components/lowBalance-popup/lowBalance';
import PermissionPopup from '../../../components/permission-popup/PermissionPopup';
import { useNavigate } from 'react-router-dom';
import MediaDetectedPopup from '../../../components/mediadetectedPopup/mediaDetectedPopup';
import { useScrollHandling } from '../../../contexts/ScrollHandingContex';


function PopularList() {
  const navigation = useNavigate();
  const profileService = ProfileService();
  const homeService = HomeService();
  const [liveBroadcastList, setLiveBroadcastList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [balanceData, setBalanceData] = useState(0);
  const [lowBalance, setLowbalance] = useState(false);
  const [allowPermissionPopup, setAllowPermissionPopup] = useState(false);
  const [permissionTypePopup, setPermissionTypePopup] = useState(null);
  const [hostDetail, setHostDetail] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);

  const [mediaDetectedPopup, setMediaDetectedPopup] = useState(false);
  const [cameraMediaType, setCameraMediaType] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [permissionType, setPermissionType] = useState(PermissionStatus);
  const listRef = useRef(null);
  const startYRef = useRef(0);

  const {
    saveDataforPublicList,
    resetDataforPublicList,
    saveDataForScrollForPublic,
    scrollPostionForPublicList,
    publicList,
    timeStampForPublicList,
    lastPageForPublicList,
  } = useScrollHandling();

  useEffect(() => {
    if (publicList.length>0) {
      setLiveBroadcastList(publicList);
      setLastPage(lastPageForPublicList);
      setTimeout(() => {
            if (scrollPostionForPublicList && listRef.current) {
              listRef.current.scrollTo(0, parseInt(scrollPostionForPublicList, 10));
            }
          }, 0);
    } else {
      resetDataforPublicList();
      fetchData(currentPage);
    }
  }, []);


  useEffect(() => {
    if (localStorage.getItem("token")) {
      GetMyBalance();
    }
  }, []);


  useEffect(() => {
    const container = listRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, currentPage, lastPage]);

  const fetchData = async (page) => {
       // 
    if (loading || page > lastPage) return;
    setLoading(true);
    try {
      const response = await homeService.LiveBroadcastList(page);
      if (response.success) {
//         setLiveBroadcastList((prev) => {
//     const updatedList = isRefreshing
//     ? response.result.data
//     : [...prev, ...response.result.data];
//   saveDataforPublicList(updatedList, Date.now().toString(), response.result.last_page);

//   return updatedList;
// });
//setLastPage(response.result.last_page);
const updatedList = isRefreshing
? response.result.data
: [...liveBroadcastList, ...response.result.data];

setLiveBroadcastList(updatedList);
setLastPage(response.result.last_page);

saveDataforPublicList(updatedList, Date.now().toString(), response.result.last_page);

      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };

  const handleScroll = () => { 
   //  
    const container = listRef.current;
    if (container) {
      saveDataForScrollForPublic(container.scrollTop.toString());
    }
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 55
    ) {
      if (!loading && currentPage < lastPage) {
        fetchNextPage();
      }
    }
  };

  const fetchNextPage = () => {  
    setCurrentPage((prev) => {
      const nextPage = prev + 1;
      fetchData(nextPage);
      return nextPage;
    });
  };

  const GetMyBalance = async () => {
    try {
      const response = await profileService.GetMyBalance();
      if (response?.success == true) {
        setBalanceData(response.maleBalance.purchase_points);
      } else {
        throw new Error(response.error);
      }
    } catch (error) {
      if (error.message === "Session expired. Please log in again.") {
        localStorage.clear();
        navigation("/login");
      } else {
        throw new Error(error.message);
      }
    }
  };

  const getBrowserPermissions = async () => {
    const cameraPermission = await navigator.permissions.query({ name: 'camera' });
    const microphonePermission = await navigator.permissions.query({ name: 'microphone' });

    return {camera: cameraPermission.state,microphone: microphonePermission.state};
  };

  const startVideoCall = (id, profileId, name, rate, image) => {

    sessionStorage.setItem('fromHome', 'true');
    const dataToPass = { id, profileId, name, image, rate };
    navigation("/video", { state: dataToPass });
  };

  const checkAllScenariosForCalls = async (id, profileId, name, rate, image) => {
    const permission = await getBrowserPermissions();
    if (permission.camera === permissionType.GRANTED && permission.microphone === permissionType.GRANTED) {
      setIsOpen(false);
      setAllowPermissionPopup(false);
      startVideoCall(id, profileId, name, rate, image);
    }
    else if (permission.camera === permissionType.PROMPT || permission.microphone === permissionType.PROMPT) {
      setPermissionTypePopup(permissionType.PROMPT);
      setConfirmationMessage('Access to your camera and microphone is required to make a call. Please allow permissions to continue.');
      setIsOpen(true);
      setAllowPermissionPopup(true);
    }
    else if (permission.camera === permissionType.DENIED || permission.microphone === permissionType.DENIED) {
      setPermissionTypePopup(permissionType.DENIED);
      setConfirmationMessage('Access to your camera and microphone is required to make a call. Please enable permissions in your browser settings.');
      setIsOpen(true);
      setAllowPermissionPopup(true);
    }
  };

  const checkVideoDevice = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === "videoinput");
      return videoDevices.length > 0;

    } catch (error) {
      console.error("Error checking devices:", error);
      return false;
    }
  };

  const checkAudioDevice = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputDevices = devices.filter(device => device.kind === "audioinput");
      return audioInputDevices.length > 0;

    } catch (error) {
      console.error("Error checking devices:", error);
      return false;
    }
  };

  const handleNavigate = async (id, profileId, name, rate, image) => {
    const videoDeviceAvailable = await checkVideoDevice();
    if (videoDeviceAvailable) {
      const audioDeviceAvailable = await checkAudioDevice();
      if (audioDeviceAvailable) {
        setHostDetail({ id, profileId, name, rate, image });
        const token = localStorage.getItem("token");
        if (!token) {
          navigation("/login", { replace: true });
        }
        else {
          if (balanceData < rate * 60) {
            setLowbalance(true);
            document.body.style.overflow = "hidden";
            return;
          }
          checkAllScenariosForCalls(id, profileId, name, rate, image);
        }
      }
      else {
        setCameraMediaType(false);
        setTitle('No Microphone Detected')
        setMessage("Your device does not have a microphone or the microphone is disabled");
        setMediaDetectedPopup(true);
      }

    }
    else {
      setCameraMediaType(true);
      setTitle('No Camera Detected')
      setMessage("It seems your device does not have a camera or the camera is disabled.");
      setMediaDetectedPopup(true);
    }
  };

  const handleRefresh = async (page) => {
    if (isRefreshing) return;
    setIsRefreshing(true);
    try {
      const response = await homeService.LiveBroadcastList(1);
      if (response.success) {
        setLiveBroadcastList(response.result.data);
        setCurrentPage(1);
        setLastPage(response.result.last_page);
      }
    } catch (error) {
      console.error("Error during refresh", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleClose = () => {
    setLowbalance(false);
    document.body.style.overflow = "auto";
  };
  const handlenavigateForhostProfile = (id, profileId, rate,broad_channel_name) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigation('/login', { replace: true })
    }
    else {
      const dataToPass = { id, profileId, balanceData,broad_channel_name };
      navigation('/profileDetails', { state: dataToPass })
    }
  };

  const allowPermission = async (
    type,
    id = null,
    profileId = null,
    name = '',
    rate = 0,
    image = ''
  ) => {
    try {

      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,

      });
      checkAllScenariosForCalls(id, profileId, name, rate, image);

    } catch (error) {
      setIsOpen(false);
      setAllowPermissionPopup(false);
    }
  };

  const closeMediaDetectedPopup = async () => {
    setMediaDetectedPopup(false);
  }
  return (
   <>
        <div className="popular-list-container container" 
        ref={listRef}
            onTouchStart={(e) => {
              startYRef.current = e.touches[0].clientY;
            }}
            onTouchMove={(e) => {
              const distance = e.touches[0].clientY - startYRef.current;
              const scrollPosition = window.scrollY;
    
              if (distance > 50 && scrollPosition === 0 && !isRefreshing) {
                handleRefresh();
              }
            }}
        >
            <div className="user-list">
              <BroadCastList
                liveBroadcastList={liveBroadcastList}
                handlenavigateForhostProfile={handlenavigateForhostProfile}
                handleNavigate={handleNavigate}
              />
              {lowBalance && <LowBalancePopup onClose={handleClose} />}
            </div>
          </div>
          {loading && (
            <div className="bottom-loader">
              <div className="loader-dots">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
            {allowPermissionPopup &&
        <PermissionPopup isOpen={isOpen} type={permissionTypePopup} hostInfo={hostDetail} message={confirmationMessage} onAllow={allowPermission}/>
      }
      {
        mediaDetectedPopup
        &&
        <MediaDetectedPopup cameraMediaType={cameraMediaType} title={title} message={message} handleOnclick={closeMediaDetectedPopup} />
      }
   </>
  )
}

export default PopularList;