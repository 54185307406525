import React from "react";
import { Navigate } from "react-router-dom";

// Higher-order component to check for authentication
const ProtectedRoute = ({ element: Component }) => {
    
  const isAuthenticated = !!localStorage.getItem("token"); // Replace 'token' with the actual key if different

  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;