const API_URL = process.env.REACT_APP_API_URL;

const defaultHeaders = {
  'Content-Type': 'application/json'
}

const Get = async (endpoint, headers = {}) => {
  const url = `${API_URL}${endpoint}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: { ...defaultHeaders, ...headers },

    });
    return handleResponse(response);
  } catch (error) {
    if (error.message === "Failed to fetch") {
      return handleError({ message: "Session expired. Please log in again." });
    }
    return handleError(error);
  }
};


const GetMathod = async (endpoint) => {
  const token = localStorage.getItem('token');
  console.log(token);
  const url = `${API_URL}${endpoint}`;
  const headers = {
    authorization: `Bearer ${token}`,
    "content-type": "application/x-www-form-urlencoded",
    appid: "21"
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};
const PostForUpdateProfile = async (endpoint, data, headers = {}, is_pic = false) => {
  const url = `${API_URL}${endpoint}`;

  try {
    const options = {
      method: "POST",
      headers: is_pic
        ? {
          //accept: "application/json",
          ...headers, // Use only necessary headers for multipart
        }
        : {

          "content-type": "application/x-www-form-urlencoded",
          ...headers, // Include additional headers for JSON
          'Content-Type': 'application/json'
        },
      body: is_pic ? data : JSON.stringify(data), // Send FormData directly if is_pic is true
    };

    const response = await fetch(url, options);
    return handleResponse(response);
  } catch (error) {
    if (error.message === "Failed to fetch") {
      return handleError({ message: "Session expired. Please log in again." });
    }
    return handleError(error);
  }
};


const Post = async (endpoint, data, headers = {}) => {
  const url = `${API_URL}${endpoint}`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: { ...defaultHeaders, ...headers },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  } catch (error) {
    if (error.message === "Failed to fetch") {
      return handleError({ message: "Session expired. Please log in again." });
    }
    return handleError(error);
  }
};


const PostMathod = async (endpoint, connectingUserId) => {
  const token = localStorage.getItem('token');

  const url = `${API_URL}${endpoint}`;
  const data = new URLSearchParams({ connecting_user_id: connectingUserId });
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        appid: "1",
        authorization: `Bearer ${token}`,
        "content-type": "application/x-www-form-urlencoded",
      },
      body: data,
    });
    return handleResponse(response);
  } catch (error) {
    console.error("API Call Failed:", error);
  }
};



const PostMathodForUpdateFCMToken = async (endpoint, fcmToken,) => {
  const token = localStorage.getItem('token');
  const url = `${API_URL}${endpoint}`;
  const data = { token: fcmToken, app_version: 1.0 };
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        appid: "21",
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  } catch (error) {
    if (error.message === "Failed to fetch") {
      return handleError({ message: "Session expired. Please log in again." });
    }
    return handleError(error);
  }
};


const PostForMultidata = async (endpoint, formData, headers = {}) => {
  const url = `${API_URL}${endpoint}`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...headers,
      },
      body: formData,
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

const Put = async (endpoint, data) => {
  const url = `${API_URL}${endpoint}`;
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

const Delete = async (endpoint) => {
  const url = `${API_URL}${endpoint}`;
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

const handleResponse = async (response) => {
  return response.json();
};

const handleError = (error) => {

  console.error("API call failed:", error);
  return { error };
};


export { Get, Post, Put, Delete, PostForMultidata, GetMathod, PostMathod, PostMathodForUpdateFCMToken, PostForUpdateProfile };
