import {Get} from '../../src/utils/ApiUtility.js'

export const HomeService = ()=>{

    const LiveBroadcastList = async(page=1)=>{
        const headers = {
            appid: "21",
          }; 
        const respones = await Get(`livebroadcastListInweb?page=${page}`,headers);
        if(respones.success === true){
            return respones;
        }else{
            throw new Error('Failed to fetch country list');
        }
    }
    const  GenerateZegoTokenInWeb = async ()=>{
        return await Get('generate-zego-token-in-web');
    }
    return {LiveBroadcastList , GenerateZegoTokenInWeb};
}