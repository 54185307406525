import React, { useEffect, useState } from 'react'
import "./broadCastList.css";
import placeholderImage from "../../assets/placeHolderForListing.webp";
import locationSvg from "../../assets/location.svg";

function BroadCastList({liveBroadcastList , handlenavigateForhostProfile , handleNavigate }) {
    

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (liveBroadcastList && liveBroadcastList.length > 0) {
          setIsLoading(false);
        }
      }, [liveBroadcastList]);

    

  return (
    <>
      {isLoading ? (
        <div className="skeleton-container">
        {[...Array(8)].map((_, index) => (
          <div key={index} className="user-box skeleton-box">
            <div className="skeleton-avatar"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-small-line"></div>
          </div>
        ))}
      </div>
      ) : (
        liveBroadcastList.map((items, index) => (
            <div
            className="user-box"
            key={`${items.id}-${index}`}
            onClick={() =>
              handlenavigateForhostProfile(
                items.id,
                items.profile_id,
                items.new_call_rate,
                items.broad_channel_name
              )
            }
            style={{
              backgroundImage: `url(${items.profile_image}), url(${placeholderImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="user-box-top">
              <div className="live-icon">
                <div className="green-dot"></div>
                <h1 className="live-text">Online</h1>
              </div>
            </div>
            <div className="user-box-bottom">
              <div className="user-box-name">
                <p className="user-name">{items.name}</p>
                <div className="location-btn">
                  <div className="location-icon-img">
                    <img
                      className="location-img"
                      src={locationSvg}
                      alt="location"
                    />
                  </div>
                  <span className="location-text">{items.city}</span>
                </div>
              </div>
              <div
                className="call-img-outer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleNavigate(
                    items.id,
                    items.profile_id,
                    items.name,
                    items.new_call_rate,
                    items.profile_image
                  );
                }}
              >
                <img
                  src={require("../../assets/call.webp")}
                  alt="call"
                  className="call-img"
                />
              </div>
            </div>
            </div>
        ))
      )}
    </>
  )
}

export default BroadCastList