import { useEffect } from 'react';

function useViewportHeight() {
  useEffect(() => {
    const setVH = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    setVH(); // Set on initial load
    window.addEventListener('resize', setVH);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', setVH);
  }, []);
}

export default useViewportHeight;