import React, { createContext, useState, useContext, useEffect } from 'react';

const CountryContext = createContext();

export const CountryProvider = ({ children }) => {
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('+91');

  // Load the selected country code from localStorage on initial render
  useEffect(() => {
    const storedCountry = localStorage.getItem('selectedCountry');
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  }, []);

  // Update both state and localStorage when selected country changes
  const updateCountryList = (list) => setCountryList(list);
  const selectCountry = (country) => {
    setSelectedCountry(country);
    localStorage.setItem('selectedCountry', country); // Store in localStorage
  };

  return (
    <CountryContext.Provider value={{ countryList, selectedCountry, updateCountryList, selectCountry }}>
      {children}
    </CountryContext.Provider>
  );
};

export const useCountry = () => useContext(CountryContext);
